tml, body { height: 100vh; background-color: black; color: white;}

div {color: white; z-index: 1;}

.red {color: #dc3545;}
.green {color: #4c9641;}

.App {
  text-align: center;
  background-color: #000000;
  height: 100%;
}

.App-logo {
  height: 80px;
  pointer-events: none;
  border-radius: 30px;
  z-index: 1;
}

@media screen and (max-width: 400px) {
  .App-logo {height: 60px;}
}

.TokenImage {
  height: 37px;
  pointer-events: none;
}

.VinceGIF {
  height: 200px;
  padding: 15px;
  border-radius: 25px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

div {
  flex: 1;
  position: relative;
}

video {
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: absolute;
  z-index: 0;
}

.Wrestler1Image {
    width: 100%;
    height: auto;
    min-width: 150px;
    max-width: 350px;
    z-index: 2;
  }
  
.Wrestler2Image {
    width: 100%;
    height: auto;
    min-width: 150px;
    max-width: 350px;
    z-index: 2;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.ScoreTextH5 {
   font-size: 19px;
}

p {font-weight: bold;}

.inline {display: inline;}

@media (max-width: 768px){
  .ScoreTextH3 {
    display: none;
  }
  .ScoreTextH5 {
    display: inline;
  }
  .HideFromPhone {
    display: none;
  }
}

@media (min-width: 768px){
  .ScoreTextH3 {
    display: inline;
  }
  .ScoreTextH5 {
    display: none;
  }
  .HideFromPhone {
    display: inline;
  }
}

@media (max-width: 992px){
  .HideFromTablet {
    display: none;
  }
}

@media (min-width: 992px){
  .HideFromTablet {
    display: inline;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

button {
max-width: 200px;
}

input {
max-width: 250px;
}

wrappableText {
word-break: break-all;
}

